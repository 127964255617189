table {
  display: block;
  font-size: 15px;
  color: #555;
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  padding: 20px 30px 40px 30px;
  overflow-y: scroll;
  table-layout: fixed;
  margin-top: 25px;
}

table a:hover {
  color: #555;
  text-decoration: none;
}

thead,
tbody {
  display: block;
  width: 100%;
}

tr {
  border-bottom: 1px solid #f3f3f3;
  width: 100%;
}

tr a, tr {
  display: flex;
  flex: 1 1 0px;
}

tr:hover {
  background-color: #f1f1f1;
}

th,
td {
  flex: 1;
  cursor: pointer;
  padding: 15px 20px;
}

.search__input {
  padding: 5px 8px;
  display: inline-block;
  background: #fff;
  border: 1px solid #eee;
  width: 60%;
  color: #555;
  font-size: 14px;
}

.search__btn {
  background-color: #4dbb90;
  border: 0;
  padding: 4px 10px;
  margin-right: 5%;
}

.search__col__label {
  color: #555;
  font-size: 14px;
}

.search__col__input {
  background: #fff;
  border: 1px solid #eee;
  padding: 5px 8px;
  width: 30%;
  margin-left: 10px;
  color: #555;
  font-size: 14px;
}

.search__input::-webkit-input-placeholder,
.search__col__input::-webkit-input-placeholder {
  color: #f3f3f3;
}